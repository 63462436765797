<template>
  <a-card :bordered="false" size="small" style="height: 240px">
    <template slot="title">库龄占比</template>
    <div ref="stockAgeChart"></div>
  </a-card>
</template>

<script>
import { stockAgeRatio } from "@/api/stats";
import { Pie } from "@antv/g2plot";

export default {
  data() {
    return {
      stockAgeChart: null,
    };
  },
  methods: {
    initData() {
      this.stockAgeChart = new Pie(this.$refs.stockAgeChart, {
        data: [],
        angleField: "amount",
        colorField: "time",
        radius: 0.9,
        label: {
          type: "inner",
          offset: "-30%",
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: "center",
          },
        },
        interactions: [{ type: "element-active" }],
        height: 160,
      });

      this.stockAgeChart.render();
      this.getStockAgeRatio();
    },
    getStockAgeRatio() {
      stockAgeRatio().then((data) => {
        this.stockAgeChart.changeData(data);
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
